import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import { useThunkDispatch } from "./util/thunk-dispatch";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/App-State";
import { User } from "./interfaces/User";
import { Request } from "./interfaces/Request";
import { getCurrentUser } from "./features/user-feature";

const AsyncLoginContainer = loadable({ loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading });
const AsyncLogoutContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading });
const AsyncProjectContainer = loadable({ loader: () => import(/* webpackChunkName: "Projects" */ "./containers/project/ProjectContainer"), loading: Loading });
const AsyncProjectDetailsContainer = loadable({ loader: () => import(/* webpackChunkName: "Projects" */ "./containers/project/ProjectDetailsContainer"), loading: Loading });
const AsyncProjectDetailsArchiveContainer = loadable({ loader: () => import(/* webpackChunkName: "Projects" */ "./containers/project/ProjectDetailsArchiveContainer"), loading: Loading });
const AsyncProjectChecklistContainer = loadable({ loader: () => import(/* webpackChunkName: "Projects" */ "./containers/checklists/ProjectChecklist"), loading: Loading });

const Routes: React.FC = () => {
    const asyncDispatch = useThunkDispatch();
    const actions = bindActionCreators({ getCurrentUser }, asyncDispatch);
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);

    React.useEffect(() => {
        actions.getCurrentUser();
    }, []);

    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer />} />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer />} />

            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/"  render={() => <AsyncProjectContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/project/:id" render={() => <AsyncProjectDetailsContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/projectarchive/:id" render={() => <AsyncProjectDetailsArchiveContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/checklist/:projectId/:id" render={() => <AsyncProjectChecklistContainer />} />
        </Switch>
    );
}

export default Routes;