import axios from "axios";
import appSettings from "../app-settings";

export const configAxios = () => {
    axios.defaults.baseURL = appSettings.apiPath;
    axios.interceptors.request.use(function (config) {
        const token = `bearer ${localStorage.getItem("jwtToken")}`;
        config.headers.Authorization = token;

        return config;
    });
};
