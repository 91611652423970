import { User, defaultUser } from "./User";
import { Request } from "./Request";

export interface AppState {
    requests: Request[];
    user: User;
}

export const initialState: AppState = {
    requests: [],
    user: defaultUser
};
