import { AppSettings } from "./interfaces/App-Settings";

const appSettings: AppSettings = {
    //apiPath: "https://localhost:44361",

    //Dev
    //apiPath: "http://api.dev-baltensperger.chili.ch",

    //Prod
    apiPath: "https://api-baltiapp.baltensperger.ch",
    forumUrl: ""
}

export default appSettings;