
import { Role } from "./Role";
import { Project } from "./Project";
import { UserRole } from "./UserRole";

export interface User {
    [key: string]: any
    id: string,
    firstName: string,
    lastName: string,
    userName: string,
    mobileNr: string,
    function: string,
    isActive: boolean,
    email: string,
    password: string,
    roleName: string,
    tradeId: number,
    projects: Project[],
    userRoles: UserRole[]
}

export const defaultUser: User = {
    id: "",
    firstName: "",
    lastName: "",
    userName: "",
    mobileNr: "",
    function: "",
    isActive: true,
    email: "",
    password: "",
    roleName: "",
    tradeId: null,
    projects: [],
    userRoles: []
}