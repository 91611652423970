import axios from "axios";
/*import { GetUsersDto } from "../interfaces/dto/GetUsersDto";*/
import { UsersModel } from "../interfaces/Models/UsersModel";
import { User } from "../interfaces/User";

export const getCurrent = () => {
    return axios.get("/user/getCurrent");
}

//export const getAll = (getUsersDto: GetUsersDto) => {
//    return axios.post("/user/getAllUsers", getUsersDto);
//}
export const getAll = (usersModel: UsersModel) => {
    return axios.post("/user/getAllUsers", usersModel);
}

export const getAllUsers = () => {
    return axios.get("/user/getAllUsers");
}

export const getAllByProjectId = async (projectId: number) => {
    return await axios.get("/user/getUsersByProjectId", { params: { projectId } })
}

export const getById = (userId: string) => {
    return axios.get("/user/getUserById", { params: { userId } })
}

export const addUser = (user: User) => {
    return axios.post("/user/createUser", user);
}
export const updateUser = (user: User) => {
    return axios.put("/user/updateUser", user);
}

export const deleteUser = (userId: string) => {
    return axios.delete("/user/deleteUserById", { params: { userId } })
}